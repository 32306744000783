(function() {
    Vue.use(VeeValidate, {
        locale: document.documentElement.lang,
        events: 'blur|submit|change',
        classes: true,
        invalidateFalse: true,
        classNames: {
            invalid: 'input--invalid',
            valid: 'input--valid'
        },
        dictionary: {
            nl: {
                messages: {
                    _default: (field) => `Dit veld is niet correct ingevuld.`,
                    after: (field, [target, inclusion]) => `Dit veld moet groter ${inclusion ? 'of gelijk aan ' : ''} ${target}.`,
                    alpha_dash: (field) => `Dit veld mag alleen alfanumerieke karakters, strepen en onderstrepingstekenen bevatten.`,
                    alpha_num: (field) => `Dit veld mag alleen alfanumerieke karakters bevatten.`,
                    alpha_spaces: (field) => `Dit veld mag alleen alfanumerieke karakters en spaties bevatten.`,
                    alpha: (field) => `Dit veld mag alleen alfabetische karakters bevatten.`,
                    before: (field, [target, inclusion]) => `De waarde van dit veld moet kleiner ${inclusion ? 'of gelijk aan' : ''} ${target} zijn.`,
                    between: (field, [min, max]) => `De waarde van dit veld moet tussen ${min} en ${max} zijn.`,
                    confirmed: (field) => `Dit bevestigingsveld komt niet overeen.`,
                    credit_card: (field) => `Dit veld is ongeldig.`,
                    date_between: (field, [min, max]) => `De datum moet tussen ${min} en ${max} zijn.`,
                    date_format: (field, [format]) => `Dit veld moet het volgende formaat hebben: ${format}.`,
                    decimal: (field, [decimals = '*'] = []) => `Dit veld mag alleen numerieke, en${!decimals || decimals === '*' ? ' ' : decimals}decimale nummers bevatten.`,
                    digits: (field, [length]) => `Dit veld moet ${length} nummers bevatten.`,
                    dimensions: (field, [width, height]) => `De dimensies voor dit veld moet ${width} pixels breed en ${height} pixels hoog zijn.`,
                    email: (field) => `Dit veld moet een geldig e-mailadres bevatten.`,
                    ext: (field) => `Dit veld moet een correct bestand bevatten.`,
                    image: (field) => `Dit veld moet een afbeelding bevatten.`,
                    included: (field) => `Dit veld moet een geldige waarde bevatten.`,
                    integer: (field) => `Dit veld moet een nummer zijn.`,
                    ip: (field) => `Dit veld moet een veilig ip adres zijn.`,
                    length: (field, [length, max]) => {
                        if (max) {
                            return `Dit veld moet minimaal ${length} karakters en maximaal ${max} karakters bevatten.`;
                        }

                        return `Dit veld moet minimaal ${length} karakters lang zijn.`;
                    },
                    max: (field, [length]) => `Dit veld mag niet meer karakters bevatten dan ${length}.`,
                    max_value: (field, [max]) => `Dit veld mag een maximale waarde hebben van ${max}.`,
                    mimes: (field) => `Dit veld moet Dit juiste type bestand bevatten.`,
                    min: (field, [length]) => `Dit veld moet minimaal ${length} karakters zijn.`,
                    min_value: (field, [min]) => `Dit veld moet een minimale waarde hebben van ${min}.`,
                    excluded: (field) => `Dit veld moet een geldige waarde bevatten`,
                    numeric: (field) => `Dit veld mag alleen numerieke karakters bevatten.`,
                    regex: (field) => `Dit veld is niet correct ingevoerd.`,
                    required: (field) => `Dit veld is verplicht.`,
                    size: (field, [size]) => `De bestandsgrootte van dit veld mag niet groter zijn dan ${formatFileSize(size)}.`,
                    url: (field) => `Dit veld moet een valide URL zijn.`
                }
            }
        }
    });
})();

Barba.Dispatcher.on("transitionCompleted", function () {
    let Global = new Vue({
        el: document.getElementById("app"),
        store,
        components: {
            'vue-recaptcha': VueRecaptcha
        },
        data() {
            return {
                bLazy: null,
                MoveToInstance: new MoveTo({
                    tolerance: 100,
                    duration: 800
                }),
                hasCaptcha: false,
                captchaExecuted: false,
                formMessage: '<div class="form-message form-message--active %status%">' +
                    '   <div class="form-message__content">%message%</div>' +
                    '   <span class="form-message__icon form-message__icon--success">' +
                    '       <svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"/></svg>' +
                    '   </span>' +
                    '   <span class="form-message__icon form-message__icon--error">' +
                    '       <svg width="32" height="32" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1088 1248v224q0 26-19 45t-45 19h-256q-26 0-45-19t-19-45v-224q0-26 19-45t45-19h256q26 0 45 19t19 45zm30-1056l-28 768q-1 26-20.5 45t-45.5 19h-256q-26 0-45.5-19t-20.5-45l-28-768q-1-26 17.5-45t44.5-19h320q26 0 44.5 19t17.5 45z"/></svg>' +
                    '   </span>' +
                    '</div>',
            }
        },
        directives: {
            clickOutside: {
                bind: function (el, binding, vNode) {
                    // Provided expression must evaluate to a function.
                    if (typeof binding.value !== 'function') {
                        const compName = vNode.context.name;
                        let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
                        if (compName) {
                            warn += `Found in component '${compName}'`
                        }

                        console.warn(warn)
                    }
                    // Define Handler and cache it on the element
                    const bubble = binding.modifiers.bubble;
                    const handler = (e) => {
                        if (bubble || (!el.contains(e.target) && el !== e.target)) {
                            binding.value(e)
                        }
                    };
                    el.__vueClickOutside__ = handler;

                    // add Event Listeners
                    document.addEventListener('click', handler)
                },
                unbind: function (el, binding) {
                    // Remove Event Listeners
                    document.removeEventListener('click', el.__vueClickOutside__);
                    el.__vueClickOutside__ = null
                },
                inserted: function(){
                    this.SmoothScroll();
                }
            }
        },
        methods: {
            Init() {
                this.Blazy();
                this.Barba();
                this.Veevalidate();
                this.ViewportHeight();
            },
            ViewportHeight(){
                window.addEventListener('resize', function(){
                    let vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                });
            },
            Blazy() {
                this.bLazy = new Blazy({
                    breakpoints: [{
                        width: 0,
                        src: 'data-src-small'
                    }, {
                        width: 640,
                        src: 'data-src-medium'
                    }, {
                        width: 1024,
                        src: 'data-src-large'
                    }, {
                        width: 1200,
                        src: 'data-src-xlarge'
                    }, {
                        width: 1440,
                        src: 'data-src-xxlarge'
                    }]
                })
            },
            Barba() {
                let FadeTransition = Barba.BaseTransition.extend({
                    start: function () {
                        // As soon the loading is finished and the old page is faded out, let's fade the new page
                        Promise
                            .all([this.newContainerLoading, this.fadeOut()])
                            .then(this.fadeIn.bind(this));
                    },

                    fadeOut: function () {
                        return new Promise((resolve, reject) => {
                            document.querySelector('.barba-bg').classList.add('barba-bg--visible');
                            window.setTimeout(function () {
                                resolve();
                            }, 150);
                        });
                    },

                    fadeIn: function () {
                        let _this = this;
                        (this.oldContainer).style.display = 'none';

                        window.scrollTo(0, 0);

                        document.querySelector('.barba-bg').classList.remove('barba-bg--visible');
                        _this.done();
                    }
                });

                Barba.Pjax.getTransition = function () {
                    return FadeTransition;
                };

                Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {
                    let regexp = /\<body.*\sclass=["'](.+?)["'].*\>/gi,
                        match = regexp.exec(newPageRawHTML);
                    if(!match || !match[1]) return;
                    document.body.setAttribute('class', match[1]);
                });
            },
            Veevalidate() {

            },
            Axios() {
                // Add a response interceptor
                axios.interceptors.response.use(function (response) {
                    // Do something with response data
                    return response;
                }, function (error) {
                    // Do something with response error
                    // todo error handling
                    return Promise.reject(error);
                });
            },
            submit: function (e) {
                const that = e.target;
                this.$validator.validateAll().then(result => {
                    if (result) {
                        that.classList.add('form--loading');
                        if (!this.hasCaptcha || this.captchaExecuted) {
                            this.submitForm(that);
                        } else {
                            this.$refs.recaptcha.execute();
                        }
                    }
                });
            },
            submitForm(form) {
                const that = this;
                if (form.querySelector('.form-message')) {
                    form.querySelector('.form-message').remove();
                }

                let messageTemplate = this.formMessage;
                let formData = new FormData(form);

                axios({
                    method: 'post',
                    url: form.action,
                    data: formData
                }).then(function (response) {

                    if (response.data.target) {
                        Barba.Pjax.goTo(response.data.target);
                        return;
                    }

                    messageTemplate = messageTemplate.replace(/%message%/, response.data.message);
                    messageTemplate = messageTemplate.replace(/%status%/, 'form-message--success');

                    form.innerHTML = messageTemplate;
                    form.classList.toggle('form--loading');

                    // Sends the event to the Google Analytics property with
                    // tracking ID GA_TRACKING_ID set by the config command in
                    // the global tracking snippet.
                    if (window.gtag !== undefined) {
                        gtag('event', 'Ingevuld', {
                            'event_category': (form.dataset.analytics !== undefined ? form.dataset.analytics : 'Formulierenbeheer')
                        });
                    }

                    this.MoveToInstance.move(document.querySelector('.form-message'));

                }).catch(function (error) {
                    if (error.response !== undefined) {
                        messageTemplate = messageTemplate.replace(/%message%/, error.response.data.message);
                        messageTemplate = messageTemplate.replace(/%status%/, 'form-message--error');

                        form.classList.toggle('form--loading');
                        form.insertAdjacentHTML('afterbegin', messageTemplate);

                        that.MoveToInstance.move(document.querySelector('.form-message'));
                    } else {
                        that.MoveToInstance.move(document.querySelector('.form-message'));
                    }
                });
            },
            onCaptchaVerified: function () {
                this.captchaExecuted = true;
                let form = document.querySelector('.form--loading');
                if (form) {
                    this.submitForm(form);
                }
            },
            SmoothScroll() {
                const that = this;

                document.querySelectorAll('a[href^="#"]').forEach(anchor => {
                    if (anchor.getAttribute('href').length > 1) {
                        anchor.addEventListener('click', function (e) {
                            e.preventDefault();

                            let target = document.querySelector(this.getAttribute('href'));
                            that.MoveToInstance.move(target);
                        });

                    }
                });
            },
            ScrollTo(target){
                let elm = document.querySelector(target);
                this.MoveToInstance.move(elm);
            }
        },
        created() {
            this.Init();
        },
        mounted() {
            this.SmoothScroll();
        }
    })
});

document.addEventListener("DOMContentLoaded", function () {
    Barba.Pjax.cacheEnabled = false;
    Barba.Pjax.start();
});

/**
 * Toevoeging voor het correct tracken in analytics
 *
 * @see https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
 */
Barba.Dispatcher.on('initStateChange', function () {
    if (Barba.HistoryManager.history.length <= 1) {
        return;
    }

    // send statics by Google Analytics(analytics.js) or Google Tag Manager
    if (typeof ga === 'function' && typeof ga.getAll === 'function') {
        var trackers = ga.getAll();
        trackers.forEach(function (tracker) {
            ga(tracker.get('name') + '.set', 'page', location.pathname);
            ga(tracker.get('name') + '.send', 'pageview');
        });
    }
});
