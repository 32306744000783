Vue.component("slider-component", {
    data: function () {
        return {

        }
    },
    mounted: function () {
        this.initSlider();
    },
    methods: {
        initSlider: function () {
            let that = this.$el;

            if(that.querySelectorAll('.hero-slider__slide').length > 1) {
                let elem = that.querySelector('.hero-slider__slides');
                this.heroSlider = new Flickity(elem, {
                    pageDots: true,
                    wrapAround: true,
                    contain: false,
                    cellAlign: 'left',
                    prevNextButtons: false,
                    autoPlay: true
                });
            }
        }
    }
});