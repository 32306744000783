Vue.component('products-component', {
    mixins: [
        Mixins.products,
        Mixins.productLightboxes
    ],
    data: function () {
        return {
            activeFilter: null,
            masonry: null
        }
    },
    mounted: function () {
        let that = this;
        setTimeout(function(){
            that.masonryGrid()
        }, 0)
    },
    methods: {
        masonryGrid: function (){
            this.masonry = new Masonry(document.querySelector('.products'), {
                itemSelector: '.products__product'
            });
        },

        toggleFilter: function(int) {
            if (this.activeFilter == int) {
                this.activeFilter = null;
            } else {
                this.activeFilter = int;
            }
        },

        closeFilter: function() {
            const that = this;

            that.$el.classList.add('products-overview--loading');
            this.activeFilter = null;

            setTimeout(function () {
                that.$el.classList.remove('products-overview--loading');
            }, 250)
        },

        openBox: function(src) {
            basicLightbox.create('<img width="auto" height="100%" src="' + src + '" />').show();
        },

        getItemsSuccess: function () {
            setTimeout(() => {
                this.masonryGrid();
            }, 100);
        },

        reset: function () {
            this.page = 1;
            this.$refs.filter.reset();
            this.masonryGrid();

            let inputs = this.$refs.filter.getElementsByTagName('input');
            for (let index = 0; index < inputs.length; index++) {
                let input = inputs[index];

                if (input.type === 'checkbox') {
                    input.checked = false;
                }
            }

            this.closeFilter();
            this.getItems(true);
        },

        ScrollTo: function (target) {
            return this.$parent.ScrollTo(target);
        }
    }
});