Mixins.productLightboxes = {
    data: function () {
        return {

        }
    },
    methods: {
        createLightboxes: function (images) {
            if (document.body.classList.contains('backoffice') || ! images.length) {
                return false;
            }

            for (let i = 0; i < images.length; i++) {
                let image = images[i];
                image.onclick = () => {
                    let src = image.src;
                    if (image.dataset.large) {
                        src = image.dataset.large;
                    }

                    basicLightbox.create('<img width="auto" height="100%" src="' + src + '" />').show();
                }
            }
        }
    },
};