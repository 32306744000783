Mixins.products = {
    data: function () {
        return {
            page: 1,
            items: [],
            loading: false,
            jsonLoaded: false,
            lastPage: 0
        }
    },
    computed: {
        showMore: function () {
            return (this.lastPage > this.page);
        }
    },
    mounted: function () {
        let cached = {};
        if (typeof (Storage) !== 'undefined') {
            if (sessionStorage.getItem(window.location.href)) {
                cached = JSON.parse(sessionStorage.getItem(window.location.href));
            }

            if (cached.lastPage) {
                this.lastPage = parseInt(cached.lastPage);
            }

            if (cached.page) {
                this.page = cached.page;
            }
        }

        let json = {};
        if (this.$refs.json) {
            json = JSON.parse(this.$refs.json.innerHTML);
            this.jsonLoaded = true;
        }

        if (this.getParameter('reset') === 'true') {
            this.getItems(true);
        } else if (json.length === undefined || cached.length > json.length) {
            this.items = cached.items;
        } else if (json.length > 0){
            this.items = json;

            if (this.$refs.list) {
                this.lastPage = parseInt(this.$refs.list.dataset.lastPage);
            }
        } else {
            this.getItems();

            if (this.$refs.list) {
                this.lastPage = parseInt(this.$refs.list.dataset.lastPage);
            }
        }
    },
    watch: {
        items: function (val, oldVal) {
            if (typeof (Storage) !== 'undefined') {
                let obj = {
                    items: val,
                    lastPage: this.lastPage,
                    page: this.page
                };

                sessionStorage.setItem(window.location.href, JSON.stringify(obj));
            }
        }
    },
    methods: {
        getParameter: function(name, url) {
            name = name.replace(/[\[\]]/g, '\\$&');

            if (! url) {
                url = window.location.href;
            }

            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            var results = regex.exec(url);

            if (!results) {
                return null;
            }

            if (!results[2]) {
                return '';
            }

            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },

        getItemsSuccess: function (response) {
            console.log(response);
        },

        getItemsError: function (error) {
            console.log(error);
        },

        getItems: function(reset) {
            if (reset) {
                this.$data.page = 1;
            }

            let queryString = serialize(this.$refs.filter);
            if (queryString) {
                queryString += '&p=' + this.page;
            } else {
                queryString += 'p=' + this.page; 
            }

            let url = '';
            let historyUrl = url;

            historyUrl += location.pathname + '?' + queryString;
            url += 'api/products?' + queryString;

            this.loading = true;

            return axios.get(url).then((response) => {
                let newItems = response.data.data;
                let meta = response.data.meta;

                this.lastPage = meta.last_page;

                if (! reset) {
                    for (var index in newItems) {
                        this.items.push(newItems[index]);
                    }
                } else {
                    this.items = newItems;
                }

                this.loading = false;
                this.getItemsSuccess(response);

                if (this.$el.dataset.id !== undefined) {
                    return true;
                }

                history.replaceState(this.items, window.document.title, historyUrl);

                return true;
            }).catch((error) => {
                this.getItemsError(error);
                return false;
            });
        },

        loadMore: function () {
            this.page = parseInt(this.page) + 1;

            return this.getItems(false);
        },
    }
};